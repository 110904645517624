import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom";

export default function Footer({ showFooter = true, isDark }) {
  const history = useHistory();
  const textColor = isDark ? "bg-light text-dark " : "bg-dark text-light";

  return (
    <div className=" row " hidden={!showFooter}>
      <div className={`container-fluid ${textColor}  pt-4 pb-4 pb-md-2`}>
        <div className="row">
          <div className="col-12 col-md-4 order-md-2">
            <p className="text-center"> {`Hecho con ♡ en  Guate 🇬🇹`}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="text-center order-md-1 ">
              <small>
                {` © Copyright 2016 - ${new Date().getFullYear()} | Virtual Tech GT Tutorías | `}
                <a onClick={() => history.push("fb-privacy-policy")}>
                  {" "}
                  Privacy Policy
                </a>
              </small>
            </p>
          </div>
          <div className="col-12 col-md-4  order-md-3 ">
            <div className="d-flex flex-row justify-content-center">
              <a
                href="https://www.facebook.com/VirtualTechGT/"
                target="_blank"
                rel="noopener noreferrer"
                className={textColor}
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="lg"
                  className="mx-3"
                />
              </a>
              <a
                href="https://www.instagram.com/virtualtechtutorias/"
                target="_blank"
                rel="noopener noreferrer"
                className={textColor}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="lg"
                  className="mx-3"
                />
              </a>

              <a
                href="https://www.youtube.com/channel/UC8GnGFyPxXdEsyUU0bWD5rw"
                target="_blank"
                rel="noopener noreferrer"
                className={textColor}
              >
                <FontAwesomeIcon icon={faYoutube} size="lg" className="mx-3" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
